import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {client} from "../../../../../services/client";
import {searchParamsToObject, toEur} from "../../../../../utils/tools";
import {notification} from "../../../../../components/Notification";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";

export const OrdersTable = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [updated, setUpdated] = useState(0);

  const [headerFields, setHeaderFields] = useState([
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'uid', value: 'User', direction: 'asc', sorted: true},
    {name: 'paymentMethod', value: 'Method', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'crypto', value: 'Crypto amount', direction: 'asc', sorted: true},
    {name: 'address', value: 'Crypto address', direction: 'asc', sorted: true},
    {name: 'invoice', value: 'Invoice', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'created', value: 'Created', direction: 'asc', sorted: true},
    {name: 'updated', value: 'Updated', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const getData = useCallback(async () => {
    const _params = new URLSearchParams(history.location.search);
    await client.getAllOrders({
      page: currentPage,
      size: rowsPerPage,
      ...searchParamsToObject(_params),
    })
      .then((response) => {
        setData(response.content);
        setTotal(response.totalElements);
      })
      .catch((error) => notification.warning(error.message));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, updated]);


  const renderStatus = (e) => {
    if (e.status === "NEW") {
      return <span className="td-label td-label-gray">{e.status.toLowerCase()}</span>
    } else if (e.status === "PENDING") {
      return <span className="td-label td-label-orange">{e.status.toLowerCase()}</span>
    } else if (e.status === "PAID") {
      return <span className="td-label td-label-green">{e.status.toLowerCase()}</span>
    } else if (e.status === "FREE") {
      return <span className="td-label td-label-limegreen">{e.status.toLowerCase()}</span>
    } else if (e.status === "PART_PAID") {
      return <span className="td-label td-label-purple">{e.status.toLowerCase()}</span>
    } else if (e.status === "EXPIRED") {
      return <span className="td-label td-label-red">{e.status.toLowerCase()}</span>
    } else if (e.status === "FAILED") {
      return <span className="td-label td-label-red">{e.status.toLowerCase()}</span>
    } else if (e.status === "GEN_CV_EXCHANGE") {
      return <span className="td-label td-label-pink">cv</span>
    } else if (e.status === "REFUNDED") {
      return <span className="td-label td-label-black">{e.status.toLowerCase()}</span>
    } else {
      return <span>{e.status.toLowerCase()}</span>
    }
  }


  const renderInvoice = (e) => {
    if (e.invoice !== undefined) {
      return <div className="link" onClick={() => window.open(e.invoice, "_blank")}>link</div>
    }
  }

  return (
    <div className="block">
      <div className="title">Orders</div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((e, index) => (
              <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#fff'}} hover key={index}>
                <TableCell>{e.id}</TableCell>
                <TableCell>{e.userId}</TableCell>
                <TableCell>{e.paymentMethod}</TableCell>
                <TableCell>{toEur(e.amount)}</TableCell>
                <TableCell>{e.cryptoAmount}</TableCell>
                <TableCell>{e.cryptoAddress}</TableCell>
                <TableCell>{renderInvoice(e)}</TableCell>
                <TableCell>{renderStatus(e)}</TableCell>
                <TableCell>{e.createdAt}</TableCell>
                <TableCell>{e.updatedAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}