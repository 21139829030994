import React, {useEffect, useState} from "react";
import "../Auth.css";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout, setAuth, setUser} from "../../../store/actions/actions";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {jwt} from "../../../services/jwt";
import {ROUTE} from "../../../constants/routes";
import {ROLE} from "../../../constants/roles";

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      const response = await client.auth2fa({email, password});
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();

        if (user.role === ROLE.ADMIN) {
          dispatch(setUser(user));
          dispatch(setAuth(true));
          history.push(ROUTE.USERS.replace(":tab", "all"));
        } else if (user.role === ROLE.TRANSLATOR || user.role === ROLE.MODERATOR) {
          dispatch(setUser(user));
          dispatch(setAuth(true));
          history.push(ROUTE.TRANSLATIONS.replace(":tab", "all"));
        } else {
          notification.warning("Access denied");
        }

        setLoading(false);
      }
    } catch (error) {
      notification.warning(error.message);
      setLoading(false);
    }
  }

  return (
    <div className="login-form">
      <div className="login-title">Login</div>
      <form onSubmit={handleLogin} className="form">
        <input
          id="email"
          type="email"
          required
          autoComplete="off"
          placeholder="Email"
        />
        <input
          id="password"
          type="password"
          required
          autoComplete="off"
          placeholder="Password"
        />
        <div>
          {loading ? <span className="loader"/> :
            <button type="submit" className="button-blue">Login</button>}
        </div>
      </form>
    </div>
  )
}