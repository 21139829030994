import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import "../../Users.css";
import { isMobile } from "../../../../../utils/checkDevice";
import { searchParamsToObject } from "../../../../../utils/tools";
import { client } from "../../../../../services/client";
import { notification } from "../../../../../components/Notification";
import { ROUTE } from "../../../../../constants/routes";

export const UserTable = () => {
  const { tab } = useParams();
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [users, setUsers] = useState();
  const history = useHistory();

  const [filterStatus, setFilterStatus] = useState(0);

  const [headerFields, setHeaderFields] = useState([
    { name: 'id', value: 'ID', direction: 'asc', sorted: true },
    { name: 'btUserId', value: 'BT user', direction: 'asc', sorted: true },
    { name: 'ref', value: 'Ref', direction: 'asc', sorted: true },
    { name: 'username', value: 'Username', direction: 'asc', sorted: true },
    { name: 'email', value: 'Email', direction: 'asc', sorted: true },
    { name: 'firstName', value: 'First name', direction: 'asc', sorted: true },
    { name: 'lastName', value: 'Last name', direction: 'asc', sorted: true },
    { name: 'kycStep', value: 'KYC step', direction: 'asc', sorted: true },
    { name: 'kycStatus', value: 'KYC status', direction: 'asc', sorted: true },
    { name: 'status', value: 'Status', direction: 'asc', sorted: true },
    { name: 'createdAt', value: 'Created', direction: 'asc', sorted: true },
    { name: 'actions', value: 'Actions', direction: 'asc', sorted: true },
  ]);

  const getUsers = useCallback(async () => {
    switch (tab) {
      case "all": {
        try {
          const _params = new URLSearchParams(history.location.search)
          const response = await client.getAllUsers({
            page: page,
            size: rowsPerPage,
            ...searchParamsToObject(_params),
          })
          setUsers(response.content);
          setTotal(response.totalElements)

        } catch (error) {
          notification.warning(error.message);
        } finally {
        }
        break;
      }
    }

  }, [page, rowsPerPage, history, tab]);

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage, tab]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname.split("/", 3)[2] === "users") {
        getUsers();
      } else {
        unlisten();
      }
    })
  }, [history]);


  const onChangePage = (_, newPage) => {
    setPage(newPage)
    // history.push(history.location.pathname + history.location.search + "?&page=" + newPage);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };


  const renderActions = (user) => {
    return (
      <>
        <Tooltip title="Details" aria-label="edit">
          <Edit className="action-icon"
            onClick={() => history.push(ROUTE.USER_DETAILS.replace(":id", user.id).replace(":tab", "info"))} />
        </Tooltip>
      </>
    )
  }

  const renderStatus = (user) => {
    if (user.status === "NEW") {
      return <Tooltip title="New user, email not confirmed" aria-label="status-new">
        <span className="td-label td-label-green">{user.status.toLowerCase()}</span>
      </Tooltip>
    }
    if (user.status === "MEMBER") {
      return <Tooltip title="Email confirmed" aria-label="status-member">
        <span className="td-label td-label-blue">{user.status.toLowerCase()}</span>
      </Tooltip>
    }
    if (user.status === "BANNED") {
      return <Tooltip title="User banned by admin" aria-label="status-banned">
        <span className="td-label td-label-red">banned</span>
      </Tooltip>
    }
  }

  const renderKycStatus = (user) => {
    if (user.kycStatus === "IN_PROGRESS") {
      return <Tooltip title="User has not completed the KYC process" aria-label="status-new">
        <span className="td-label td-label-orange">not_started</span>
      </Tooltip>
    }
    if (user.kycStatus === "PENDING") {
      return <Tooltip title="User has completed KYC and waiting for the response from KYC provider" aria-label="status-member">
        <span className="td-label td-label-green">pending</span>
      </Tooltip>
    }
    if (user.kycStatus === "REJECTED") {
      return <Tooltip title="User banned by admin" aria-label="status-banned">
        <span className="td-label td-label-red">rejected</span>
      </Tooltip>
    }
    if (user.kycStatus === "APPROVED") {
      return <Tooltip title="KYC approved" aria-label="status-waiting">
        <span className="td-label td-label-blue">approved</span>
      </Tooltip>
    }
  }

  const renderKycStep = (user) => {
    if (user.kycStep === 0) {
      return <Tooltip title="On step Basic info" aria-label="status-new">
        <span className="">0</span>
      </Tooltip>
    }
    if (user.kycStep === 1) {
      return <Tooltip title="On step Residential address" aria-label="status-member">
        <span className="">1</span>
      </Tooltip>
    }
    if (user.kycStep === 2) {
      return <Tooltip title="On step Shipping address" aria-label="status-waiting">
        <span className="">2</span>
      </Tooltip>
    }
    if (user.kycStep === 3) {
      return <Tooltip title="On step Verify identity" aria-label="status-banned">
        <span className="">3</span>
      </Tooltip>
    }
    if (user.kycStep === 4) {
      return <Tooltip title="On step verify address" aria-label="status-banned">
        <span className="">4</span>
      </Tooltip>
    }
    if (user.kycStep === 5) {
      return <Tooltip title={user.kycStatus === "PENDING" ? "Waiting for KYC provider approve" : "Approved"} aria-label="status-banned">
        <span className="">5</span>
      </Tooltip>
    }
  }


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params.get('status');
    if (status) {
      setFilterStatus(status);
    }
  }, [history]);

  const generateLink = (excludedVarName) => {
    const params = new URLSearchParams(history.location.search)
    const status = params.get('status');

    let link = "?";
    if (status !== null && excludedVarName !== "status") {
      link = link + "&status=" + status;
    }
    return link;
  }

  const search = (e) => {
    e.preventDefault();
    const link = generateLink("");
    history.push(link + "&text=" + e.target.search.value);
    getUsers();
  }

  const handleChangeFilterStatus = (e) => {
    const status = e.target.value;
    const link = generateLink("status");
    setFilterStatus(status);
    if (status !== 0) {
      history.push(link + "&status=" + status);
    } else {
      history.push(link);
    }
    getUsers();
  }

  const renderLink = (link) => {
    if (link) {
      return <div className="link" onClick={() => window.open(link, "_blank")}>link</div>
    }
  }

  return (
    <div className="users">

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.map((user, index) => (
              <TableRow style={{ backgroundColor: index % 2 ? '#fff' : '#fff' }} hover key={index}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.btUserId}</TableCell>
              <TableCell>{user.refId}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>{renderKycStep(user)}</TableCell>
              <TableCell>{renderKycStatus(user)}</TableCell>
              <TableCell>{renderStatus(user)}</TableCell>
              <TableCell>{user.createdAt}</TableCell>
              <TableCell>{renderActions(user)}</TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <div className="reset-filters" onClick={() => history.push(ROUTE.USERS)}>Reset</div>
    </div>
  )
}