import React, {useEffect, useState} from "react";
import "./Sidebar.css";
import {ROUTE} from "../../constants/routes";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "../../utils/checkDevice";
import {setSidebar} from "../../store/actions/actions";

export const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState();
  const [menu, setMenu] = useState();
  const user = useSelector(store => store.base.user);
  const sidebar = useSelector(store => store.base.sidebar);


  const handlePath = () => {
    menu && menu.forEach((e) => {
      if (location.pathname.startsWith(e.url)) {
        setActiveItem(e.id);
      }
    });
  }

  useEffect(() => {
    if (user) {
      if (user.role === "ROLE_ADMIN") {
        setMenu([
          {id: 1, title: "Users", url: ROUTE.USERS.replace(":tab", "all")},
          {id: 2, title: "Orders", url: ROUTE.ORDERS.replace(":tab", "all")},
        ])
      } else if (user.role === "ROLE_MODERATOR") {
        setMenu([
          {id: 1, title: "Users", url: ROUTE.USERS.replace(":tab", "all")},
          {id: 2, title: "Orders", url: ROUTE.ORDERS.replace(":tab", "all")},
        ])
      }
    }
  }, [user])

  useEffect(() => {
    if (menu) {
      handlePath();
    }
  }, [menu]);

  useEffect(() => {
    history.listen((location) => {
      handlePath();
    })
  }, [history, menu]);

  const renderSidebar = () => {
    if (!isMobile() || (isMobile() && sidebar)) {
      return <div className="sidebar">
        <div className="sidebar-menu">
          {menu && menu.map((e, i) => {
            return <div
              className={activeItem && activeItem === e.id ? "sidebar-menu-item sidebar-menu-item-active" : "sidebar-menu-item"}
              key={i}
              onClick={() => {
                history.push(e.url);
                if (isMobile()) {
                  dispatch(setSidebar(!sidebar));
                }
              }}>
              <div className={"sidebar-menu-icon" + e.id}/>
              <div>{e.title}</div>
            </div>
          })}
        </div>
      </div>
    }
  }

  return (
    <>
      {renderSidebar()}
    </>
  )
}