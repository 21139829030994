export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    ALL: 'search/users',
    GET_BY_ID: (id) => `search/users/${id}`,
    UPDATE: (id) => `admin/users/${id}`,
  },

  ORDERS: {
    ALL: 'search/orders',
    GET_BY_ID: (id) => `search/orders/${id}`,
  }
};
