export const ROUTE = {
  ROOT: "/",
  LOGIN: "/login",
  MAIN: "/dashboard/main",
  USERS: "/dashboard/users/:tab",
  USER_DETAILS: "/dashboard/users/:id/details/:tab",
  USER_TRANSACTION: "/dashboard/users/:id/transactions/:tab",
  USER_TREE: "/dashboard/users/:id/tree/:tab",
  TRANSLATIONS: "/dashboard/translations/:tab",
  REPORTS: "/dashboard/reports/:tab",
  PAYOUTS: "/dashboard/payouts",
  WITHDRAW_FROM_CRYPTO_API: "/dashboard/payouts/withdrawFromCryptoApi",
  EPINS: "/dashboard/epins",
  VAULTS: "/dashboard/vaults/:tab",
  LOYALTY: "/dashboard/loyalty/:tab",
  PACKS: "/dashboard/packs/:tab",
  PACKS_EDIT: "/dashboard/packs/:id/edit",
  NEWS: "/dashboard/news",
  NEWS_CREATE: "/dashboard/news/create",
  TRANSACTIONS: "/dashboard/transactions/:tab",
  ORDERS: "/dashboard/orders/:tab",
  EDUCATION: "/dashboard/education/:tab",
  RESOURCES: "/dashboard/resources/:tab",
  EVENTS: "/dashboard/events/:tab",
  EXCHANGE: "/dashboard/exchange/:tab",
}
