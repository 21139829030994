import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {DashboardLayout} from "./components/Layouts/DashboardLayout/DashboardLayout";
import {GuestLayout} from "./components/Layouts/GuestLayout/GuestLayout";
import {Login} from "./pages/auth/login/Login";
import {ROUTE} from "./constants/routes";
import {Users} from "./pages/dashboard/users/Users";
import {Orders} from "./pages/dashboard/orders/Orders";


export const useRoutes = (isAuth) => {
  if (isAuth) {
    return (
      <DashboardLayout>
        <Route path={ROUTE.USERS} exact>
          <Users/>
        </Route>

        <Route path={ROUTE.ORDERS} exact>
          <Orders/>
        </Route>

      </DashboardLayout>
    )
  }
  return (
    <GuestLayout>
      <Switch>
        <Route path={ROUTE.LOGIN}>
          <Login/>
        </Route>
        <Redirect to={ROUTE.LOGIN}/>
      </Switch>
    </GuestLayout>
  )
}