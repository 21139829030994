import React, {useEffect, useState} from "react";
import "./TopPanel.css";
import {useDispatch, useSelector} from "react-redux";
import {ROUTE} from "../../constants/routes";
import {useHistory} from "react-router-dom";
import {logout, setSidebar} from "../../store/actions/actions";
import {isMobile} from "../../utils/checkDevice";
import {Logout} from "@mui/icons-material";
import {Search} from "./components/Search/Search";

export const TopPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const sidebar = useSelector(store => store.base.sidebar);
  const [showSearch, setShowSearch] = useState(false);

  const handleShowingSearch = () => {
    const p = location.pathname;
    if (p.includes("users") || p.includes("translations")) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }

  useEffect(() => {
    handleShowingSearch();
  }, []);

  useEffect(() => {
    history.listen((location) => {
      handleShowingSearch();
    })
  }, [history]);


  return (
    <div className="top-panel">
      {!isMobile() ? <div className="logo" onClick={() => history.push(ROUTE.MAIN)}/>
        :
        <IconHamburger onClick={() => {dispatch(setSidebar(sidebar !== true))}}/>
      }
      {showSearch && <Search/>}
      <div className="top-panel-right-menu">
        <div className="top-panel-right-menu-item" onClick={() => dispatch(logout())}>
          {isMobile() ? <Logout/> : "Logout"} </div>
        {!isMobile() && user && <div className="top-panel-right-menu-item">
          <div className="top-panel-user-item">
            <div className="top-panel-avatar"/>
            <div>{user.username}</div>
          </div>
        </div>
        }
      </div>
    </div>

  )
}